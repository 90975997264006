'use client';

import { Session } from 'next-auth';
import { useSession } from 'next-auth/react';
import Link from 'next/link';
import ActionButton from '../../form/ActionButton/ActionButton';
import Badge from '../../ui/Badge/Badge';
import ToggleIconButton from '../../ui/ToggleIconButton/ToggleIconButton';
import styles from './CommonHeader.module.scss';

import { useIsMenuOpenedAtom } from '../CommonMenu/CommonMenu';

function CommonHeader({
  initialSessionData,
  isOptionNotEntered = false,
  hasPendingEntry = false,
  siteName,
}: {
  initialSessionData?: ReturnType<typeof useSession>['data'];
  isOptionNotEntered?: boolean;
  hasPendingEntry?: boolean;
  siteName: string;
}) {
  const { data: sessionData = initialSessionData } = useSession();
  const [isMenuOpenedValue, setIsMenuOpened] = useIsMenuOpenedAtom();

  const showBadge = (session: Session) => {
    return session.user.unreadMessageBoxCount > 0 || isOptionNotEntered || hasPendingEntry;
  };

  return (
    <header className={`${styles.componentStyle} ${isMenuOpenedValue ? styles.menuOpened : ''}`}>
      <div className={styles.container}>
        <Link href='/' className={styles.logo}>
          <img src='/logo.png' alt={siteName} />
        </Link>
        {sessionData ? (
          <div className={styles.menuWrapper}>
            <ToggleIconButton
              isActive={isMenuOpenedValue}
              onClick={() => setIsMenuOpened(!isMenuOpenedValue)}
            ></ToggleIconButton>
            {showBadge(sessionData) && (
              <div className={styles.menuBadge}>
                <Badge color='primary'></Badge>
              </div>
            )}
          </div>
        ) : (
          <div className={styles.auth}>
            <Link href='/auth/signin' className={styles.loginLink}>
              ログイン
            </Link>
          </div>
        )}
      </div>
    </header>
  );
}

export default CommonHeader;
