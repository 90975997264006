import { differenceInDays, getHours, getMinutes, getSeconds, isAfter, isBefore } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

// スペースが入るとエラーになるのを回避するためのnew Date()
export const newDate = (dateStr = '') => {
  if (dateStr) {
    dateStr = dateStr.replace(/ /g, 'T'); // 半角スペースをTに置換
    return new Date(dateStr);
  }
  return utcToZonedTime(new Date(), 'Asia/Tokyo');
};

// 期間内かどうか
export const isPeriod = (startAtStr: string, endAtStr: string) => {
  const isStarted = isAfter(newDate(), newDate(startAtStr));
  const isYetEnd = isBefore(newDate(), newDate(endAtStr));
  return isStarted && isYetEnd;
};

// 差分取得
// date-fnsだと合計差分しかとれないので自前で実装
export const diffDateTimeHours = (laterDate: Date, earlierDate: Date) => {
  let diffSeconds = getSeconds(laterDate) - getSeconds(earlierDate);
  let diffMinutes = getMinutes(laterDate) - getMinutes(earlierDate);
  let diffHours = getHours(laterDate) - getHours(earlierDate);
  const diffDays = differenceInDays(laterDate, earlierDate);

  // 繰り上がり処理
  if (diffSeconds < 0) {
    diffSeconds = 60 + diffSeconds;
    diffMinutes--;
  }

  if (diffMinutes < 0) {
    diffMinutes = 60 + diffMinutes;
    diffHours--;
  }

  if (diffHours < 0) {
    // daysだけはライブラリから取得してるので繰り下がり不要
    diffHours = 24 + diffHours;
  }

  return {
    days: diffDays,
    hours: diffHours,
    minutes: diffMinutes,
    seconds: diffSeconds,
  };
};
